import React, {useEffect} from "react";
import {useAuth} from "../components/auth.context";
import {Navigate} from "react-router-dom";

const Login:React.FC<{}> = () => {
    const auth = useAuth();
    useEffect(()=>{
        if(!auth.keycloak.authenticated) {
            auth.keycloak.login({
                redirectUri: String(process.env.REACT_APP_KEYCLOAK_REDIRECT_URI)
            }).then()
        }
    },[])

    if(auth.keycloak.authenticated) {
        return <Navigate to={"/"}/>
    }
    return null;
}

export default Login;
